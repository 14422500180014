import React from "react"
import {
  Section,
  H2Title,
  Container,
  M,
  GrayBorder,
  Output,
} from "../../../globalStyle"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import NextPreviousPager from "../../UI/NextPreviousPager"
import ISingleTemplate from "./types"

export const ImageWrapper = styled.div`
  width: 100%;
  height: 554px;
  & > div {
    height: 100%;
  }
  @media (max-width: 780px) {
    height: 100%;
  }
`

const SingleTemplate = (props: ISingleTemplate) => {
  const { prefixUrl, pageContext, pageData } = props
  const content = pageData.content
  const extension = pageData.picture.file.fileName.slice(
    pageData.picture.file.fileName.length - 3,
    pageData.picture.file.fileName.length
  )
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <GatsbyImage
            alt={pageData.title}
            style={{ width: "100%", height: "100%" }}
            image={node.data.target.gatsbyImageData}
          />
        )
      },
    },
  }
  const output = renderRichText(content, options)
  return (
    <Section style={{ marginTop: "80px" }}>
      <M>
        <Container>
          <H2Title>{pageData.title}</H2Title>
        </Container>
      </M>
      <GrayBorder />
      <ImageWrapper>
        {extension === "svg" ? (
          <img src={pageData.panoramaPicture.file.url} />
        ) : (
          <GatsbyImage
            image={pageData.panoramaPicture.gatsbyImageData}
            alt={pageData.title}
          />
        )}
      </ImageWrapper>
      <M>
        <Container>
          <article>
            <Output>{output}</Output>
          </article>
        </Container>
        <GrayBorder />
        <Container>
          <NextPreviousPager prefixUrl={prefixUrl} pageContext={pageContext} />
        </Container>
      </M>
    </Section>
  )
}

export default SingleTemplate
