import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import { graphql } from "gatsby"
import SingleTemplate from "../components/Sections/SingleTemplate"

export const query = graphql`
  query($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      title
      slug
      keywords
      metaDescription
      content {
        raw
      }
      picture {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        title
        file {
          fileName
          url
        }
      }
      panoramaPicture {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        file {
          fileName
          url
        }
      }
      shortDescription {
        shortDescription
      }
    }
  }
`

const ProductsTemplate = props => {
  return (
    <Layout>
      <Head
        description={props.data.contentfulProduct.metaDescription}
        pageTitle={props.data.contentfulProduct.title}
        keywords={props.data.contentfulProduct.keywords}
      />
      <SingleTemplate
        pageData={props.data.contentfulProduct}
        pageContext={props.pageContext}
        prefixUrl="products"
      />
    </Layout>
  )
}

export default ProductsTemplate
